.right-side-preview {
    width: 100%; /* Small preview container width */
    height: 100%; /* Small preview container height */

  }
  
  .cv-preview-inner {
    width: 100%; /* Full width of the parent container */
    height: 100%; /* Full height of the parent container */
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: top left;
/* Adjust scale to fit inside the smaller preview container */
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 5px; /* Add space above the buttons */
  }
  
  .pagination-controls button {
    padding: 5px 8px;
    font-size: 12px; /* Adjust font size for smaller buttons */
    cursor: pointer;
    background-color: #007BFF; /* Primary button color */
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination-controls span {
    font-size: 12px; /* Adjust font size for smaller text */
    color: #333;
  }
  