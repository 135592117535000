/* General Container Styles */
.register-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    width: 100%;
    max-width: 1140px;
}

/* Content Box Styling */
.register-content {
    background-color: #fff;
    padding: 50px 40px;
    max-width: 1140px;
    width: 100%;
    border-radius: 12px;

    text-align: center;
}

/* Title Styling */
.register-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    color: #2d3748;
}

/* Form Styling */
.register-form {
    width: 100%;
    margin-top: 20px;
}

.register-form-item {
    margin-bottom: 20px;
    text-align: left;
    position: relative;
}

.register-form-item label {
    display: block;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: #4a5568;
}

.register-form-item input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box;
    background-color: #fff;
}

.register-form-item input:focus {
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.3);
    outline: none;
}

/* Password Input with Toggle */
.password-input {
    position: relative;
}

.password-input input {
    padding-right: 50px;
}

.toggle-password2 {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #a0aec0;
    transition: color 0.3s ease;
}

.toggle-password2:hover {
    color: #3182ce;
}

/* Button Styling */
.register-button2 {
    width: 30%;
    background-color: #0072bf;
    color: white;
    font-size: 16px;
    padding: 12px;

    border-radius: 4px;
    cursor: pointer;
 
    font-weight: 600;
    text-align: center; /* Ensure the text is centered */
    box-sizing: border-box;
}

.register-button2:hover {
    background-color: #2b6cb0;
    box-shadow: 0 5px 10px rgba(49, 130, 206, 0.3);
}

/* Social Button Styling */
.social-button2 {
    width: 30%;
    font-size: 16px;
    padding: 14px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: white;
    font-weight: 600;
    border: none;
}

.social-button2.facebook {
    background-color: #3b5998;
    width: 30%;
}

.social-button2.facebook:hover {
    background-color: #2d4373;
}

.social-button2.google {
    background-color: #db4437;
    width: 30%;
}

.social-button2.google:hover {
    background-color: #c33d32;
}

.social-button2 .icon {
    margin-right: 12px;
    font-size: 18px;
}

/* Link Styling */
.back-to-login {
    text-align: center;
    margin-top: 25px;
    font-size: 14px;
}

.back-to-login a {
    color: #3182ce;
    text-decoration: none;
    font-weight: 600;
}

.back-to-login a:hover {
    text-decoration: underline;
}
