/* General Backdrop */
.cookie-backdrop, .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Cookie Container */
  .cookie-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    max-width: 900px;
    width: 90%;
  }
  
  .cookie-content {
    text-align: center;
  }
  
  .cookie-heading {
    margin: 0;
    font-size: 1.75rem;
    color: #222;
    font-weight: 700;
  }
  
  .cookie-text {
    margin: 10px 0 20px;
    color: #444;
    font-size: 1.1rem;
    line-height: 1.6;
  }
  
  /* Action Buttons */
  .cookie-actions {
    display: flex;
    gap: 10px;
  }
  
  .cookie-button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .accept-all {
    background-color: #28a745;
    color: #ffffff;
  }
  
  .block-all {
    background-color: #dc3545;
    color: #ffffff;
  }
  
  .selected-cookies {
    background-color: #ffc107;
    color: #ffffff;
  }
  
  .cookie-button:hover {
    transform: translateY(-2px);
  }
  
  .accept-all:hover {
    background-color: #218838;
  }
  
  .block-all:hover {
    background-color: #c82333;
  }
  
  .selected-cookies:hover {
    background-color: #e0a800;
  }
  
  /* Modal Container */
  .modal-container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    text-align: center;
  }
  
  .modal-heading {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .modal-content {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button {
    background-color: #6c757d;
  }
  
  .modal-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button:hover {
    background-color: #5a6268;
  }
  