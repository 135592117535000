.back-button {
  margin-bottom: 20px;
}

.custom-form {
  max-width: 800px;
  margin: 0 auto;
}

.form-item {
  margin-bottom: 16px;
}

.custom-input {
  width: 100%;
}

.field-icon {
  color: green;
}

.search-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.search-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
}

.search-input {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}

.search-tip {
  margin-top: 8px;
  color: #666;
}

.search-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
  height: 200px;
}

.search-result-item {
  padding: 8px 0;
  width: 100%;
}

.search-result-item .ant-btn {
  padding: auto;
  color: #005a9c;
  border: 1px solid black;
}

.search-result-item .ant-btn span {
  padding: 2px;
  text-align: justify;
}

.search-result-item .ant-btn:hover {
  background-color: #005a9c;
  color: white;
}

.search-result-item .ant-btn .anticon {
  margin-right: 8px;
}

.react-quill {
  height: 300px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .search-panel {
    width: 100%;
    margin-top: 16px;
  }

  .search-input {
    flex-direction: column;
    margin-bottom: 16px;
  }

  .search-input input {
    margin-bottom: 8px;
  }

  .search-input button {
    width: 100%;
  }
}

.work-summary-col {
  margin-bottom: 16px;
}

.work-summary-card {
  position: relative;
  padding: 16px;
  border-radius: 8px;
  background: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.work-summary-content {
  display: flex;
  flex-direction: column;
}

.work-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.work-summary-index {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #005a9c;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.work-summary-info {
  flex-grow: 1;
}

.work-summary-title {
  font-weight: bold;
  font-size: 16px;
}

.work-summary-employer {
  color: #555;
  margin-bottom: 4px;
}

.work-summary-location {
  color: #777;
  margin-bottom: 4px;
}

.work-summary-date {
  color: #999;
}

.work-summary-actions {
  display: flex;
  gap: 8px;
}
