.form-header {
    margin-bottom: 24px;
  }
  
  .go-back-link {
    display: block;
    margin-bottom: 16px;
    color: #1890ff;
    text-decoration: none;
  }
  
  .upload-photo .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-bottom: 16px;
  }
  
  .upload-photo .ant-upload-text {
    margin-top: 8px;
  }
  
  .custom-form .form-item {
    margin-bottom: 16px;
  }
  
  .custom-form .custom-input {
    width: 100%;
  }
  
  .custom-form .field-icon {
    color: #52c41a;
  }
  
  .additional-info-button {
    margin-right: 8px;
    margin-top: 8px;
  }
  