.homepage-container2 {
    /* Container for the homepage content */

    margin-left: 16px;
    width: 100%;
    max-width: 1140px;
  }
  
  .homepage-findjob-container {
    /* Additional styles for the FindJob container if needed */
  }
  