.cv-template2 {
  display: flex;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.cv-sidebar {
  width: 30%;
  background-color: #1e1e2d; /* Dark background for the sidebar */
  color: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cv-picture {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 20px;
  border: 3px solid #fff;
}

.contact-info p {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.contact-info p svg {
  margin-right: 10px;
}

.cv-main {
  width: 70%;
  padding: 0;
}

.cv-header2 {
  background-color: #e7b344;
  padding: 40px;
  margin-bottom: 30px;
  position: relative;
}

.cv-name1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.cv-title {
  font-size: 18px;
  color: #333;
  margin-top: 5px;
}

.cv-section {
  margin-bottom: 30px;
  padding: 0 40px;
}

.cv-section-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
  border-bottom: 2px solid #f7b731;
  padding-bottom: 5px;
}

.cv-section-content {
  font-size: 14px;
}

.cv-experience-item {
  margin-bottom: 20px;
}

.cv-experience-item h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.cv-experience-item p {
  margin: 5px 0;
}

.cv-section-content ul {
  padding: 0;
  list-style-type: none;
}

.cv-section-content li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
}
