/* General Layout Styles */
.help-container {
  padding: 40px 10px;
  width: 100%;
  max-width: 1140px;
  margin-top: 70px;
  background-color: #fff; /* Light background color for contrast */
}
.help-page-content {
  padding: 50px 20px;
  margin-top: 64px;
}

/* Row Styles */
.help-page-row {
  margin-bottom: 50px;
}

/* Title Styles */
.help-page-title {
  margin: 0 0 20px;
  font-size: 28px;
  font-weight: bold;
  color: #2d3748;
}

/* Paragraph Styles */
.help-page-paragraph {
  text-align: justify;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 10px;
  color: #4a5568;
}

/* Image Styles */
.help-page-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Register Section Styles */
.help-page-register {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

.animated-button {
  font-size: 16px;
  background-color: #0072BF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.animated-button:hover {
  background-color: #005999;
  box-shadow: 0 5px 10px rgba(0, 114, 191, 0.3);
}

.animated-arrow {
  margin-left: 10px;
  transition: margin-left 0.3s ease;
}

.animated-button:hover .animated-arrow {
  margin-left: 15px;
}

/* Modal Styles */
.ant-modal-title {
  font-size: 22px;
  font-weight: 600;
  color: #2d3748;
}

.ant-modal-footer .animated-button {
  width: auto;
  padding: 10px 20px;
  font-size: 16px;
}
