.custom-form {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .custom-input {
    width: 100%;
  }
  
  .field-icon {
    color: #52c41a;
  }
  
  .back-button {
    margin-bottom: 16px;
  }
  
  .page-title-wrap {
    margin-bottom: 24px;
  }
  
  .page-title {
    margin-bottom: 0;
  }
  
  .sub-title {
    margin-top: 0;
  }
  
  .button-off {
    display: inline-block;
    font-size: 14px;
    color: #0070B8;
    text-align: center;
    cursor: pointer;
    padding: 8px 16px;
    border: 1px solid #0070B8;
    border-radius: 4px;
    background: #fff;
  }
  
  .card-container {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .card-header-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .card-header-info span {
    font-weight: bold;
    font-size: 18px;
  }
  
  .card-actions {
    display: flex;
    gap: 8px;
  }
  
  .accordion {
    margin-top: 24px;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
  
  .accordion-header {
    padding: 16px;
    background-color: #f5f5f5;
    cursor: pointer;
  }
  
  .accordion-content {
    padding: 16px;
    display: none;
  }
  
  .accordion-content.show {
    display: block;
  }
  
  .next-button {
    margin-top: 16px;
  }
  