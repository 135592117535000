.cv-template1 {
  width: 100%;
  margin: 0 auto;
  border: none;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
}

.cv-header {
  display: flex;
  align-items: center;
  background-color: #b03a3a;
  padding: 20px;
  color: #fff;
  border-radius: 8px;
  position: relative;
  height: 160px;
  overflow: visible;
}

.cv-header .cv-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute;
  bottom: -60px;
  left: 20px;
  border: 4px solid #fff;
}

.header-text {
  margin-left: 160px;
  flex-grow: 1;
  text-align: right;
}

.header-text h1 {
  font-size: 2.5em;
  margin: 0;
  font-weight: bold;
  text-transform: capitalize;
}

.header-text h2 {
  font-size: 1.5em;
  margin: 0;
  font-weight: 300;
}

.cv-content {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.cv-sidebar {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.cv-main-content {
  flex: 2;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-section,
.skills-section,
.languages-section,
.education-section {
  margin-bottom: 20px;
}

.contact-section h2,
.skills-section h2,
.languages-section h2,
.education-section h2,
.about-section h2,
.experience-section h2,
.achievements-section h2 {
  font-size: 1.5em;
  color: #b03a3a;
  margin-bottom: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin: 5px 0;
  white-space: normal;
}

.contact-item .anticon {
  margin-right: 10px;
  flex-shrink: 0;
}

.contact-item p,
.contact-item span {
  margin: 0;
  line-height: 1.5;
  overflow-wrap: break-word;
  word-break: break-word;
}

.skills-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.skills-section li {
  font-size: 1em;
  padding: 2px 0;
}

.languages-section .language-item {
  margin-bottom: 10px;
}

.languages-section .language-stars {
  margin-top: 5px;
  color: #f39c12;
}

.experience-item {
  margin-bottom: 20px;
}

.experience-item h3 {
  font-size: 1.2em;
  margin: 0;
  color: #b03a3a;
}

.experience-item p {
  margin: 0;
  text-align: justify;
  font-size: 1em;
  color: #333;
}

.experience-item div {
  margin: 5px 0;
  text-align: justify;
}

.achievements-section p {
  margin-top: 10px;
}

/* Responsive Styles */
@media screen and (min-width: 768px) {
  .cv-content {
    flex-direction: row;
  }

  .cv-sidebar {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .cv-main-content {
    flex: 1;
    max-width: 65%;
  }
}

@media screen and (max-width: 767px) {
  .cv-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 12px;
    padding-bottom: 40px; /* Additional padding to prevent text overlap */
  }

  .cv-header .cv-picture {
    position: static;
    margin-bottom: 20px;
  }

  .header-text {
    margin-left: 0;
    text-align: center;
  }

  .header-text h1 {
    font-size: 1rem;
  }

  .header-text h2 {
    font-size: 1.6em;
  }

  .cv-sidebar,
  .cv-main-content {
    max-width: 100%;
  }

  .cv-sidebar {
    margin-bottom: 20px;
  }
}

/* Styles for Width and Height Below 300px */
@media screen and (max-width: 300px) and (max-height: 300px) {
  .cv-template1 {
    font-size: 0.5rem; /* Make the base font smaller */
    padding: 3px; /* Further reduce padding */
  }

  .cv-header {
    padding: 5px; /* Further reduce padding */
    height: 50px; /* Further reduce height */
  }

  .cv-header .cv-picture {
    width: 20px; /* Further reduce picture size */
    height: 20px;
    bottom: -8px; /* Adjust positioning */
    left: 5px;
  }

  .header-text {
    margin-left: 30px; /* Further reduce margin */
    text-align: left; /* Align text to the left */
    white-space: nowrap; /* Prevent text wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    overflow: hidden; /* Prevent text overflow */
  }

  .header-text h1 {
    font-size: 0.7rem; /* Further reduce font size */
    line-height: 1; /* Adjust line height */
  }

  .header-text h2 {
    font-size: 0.5rem; /* Further reduce font size */
    line-height: 1; /* Adjust line height */
  }

  .cv-sidebar,
  .cv-main-content {
    padding: 2px; /* Further reduce padding */
    margin-bottom: 3px; /* Further reduce margin */
  }

  .contact-section h2,
  .skills-section h2,
  .languages-section h2,
  .education-section h2,
  .about-section h2,
  .experience-section h2,
  .achievements-section h2 {
    font-size: 0.5rem; /* Further reduce font size */
    margin-bottom: 2px;
  }

  .contact-item {
    margin: 1px 0; /* Further reduce margin */
  }

  .experience-item h3 {
    font-size: 0.5rem; /* Further reduce font size */
  }

  .experience-item p {
    font-size: 0.45rem; /* Further reduce font size */
  }

  .cv-content {
    margin-top: 3px; /* Further reduce margin */
    padding-top: 2px; /* Further reduce padding */
    overflow-y: auto; /* Enable scrolling if necessary */
  }
}
