/* Layout for the FindUsers component */
.find-users-layout {
  background-color: white;
  padding: 20px;
}

/* General container styling */
.content-container {
 display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1140px;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 90px;
 
}

/* Filter row and search group */
.filter-row {
  display: flex;
  align-items: center; /* Align items vertically centered */
  justify-content: space-between; /* Distribute space evenly between elements */
  margin-bottom: 20px;
  gap: 10px; /* Ensure consistent spacing between elements */
  width: 100%; /* Ensure it takes the full width */
}

.search-group {
  display: flex;
  width: 100%; /* Ensure it takes the full width */
  gap: 10px; /* Consistent spacing between inputs */
}

.filter-dropdown {
  flex: 1; /* Allow the dropdowns to take up equal space */
  min-width: 200px; /* Increase the minimum width for better alignment */
}

.search-input {
  flex: 2; /* Make the search input wider */
}

/* Job cards container */
.job-cards-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

/* Job card columns */
.job-card-col {
  width: 100%;
}

/* Pagination styling */
.pagination {
  margin-top: 20px;
  text-align: center;
}

/* Mobile specific adjustments */
@media (max-width: 878px) {
  .filter-row {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .search-group {
    flex-direction: column;
  }

  .content-container {
    max-width: 1200px;
    margin-top: 90px;
    margin-left: 42px;
    margin-right: 10px;
  }

  .filter-dropdown, .search-input {
    width: 100%; /* Ensure full width on mobile */
  }

  .job-cards-container {
    margin-top: 10px;
  }

  .filter-tags-row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .filter-tag-col {
    margin-bottom: 10px;
  }
}

@media (max-width: 1200px) and (min-width: 879px) {
  .content-container {
    max-width: 1100px;
    margin-top: 90px;
    margin-left: 62px;
  }
}


