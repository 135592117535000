/* Align buttons in a row for desktop mode */
@media (min-width: 768px) {
    .action-buttons {
      display: flex;
      gap: 8px;
    }
  }
  
  /* Stack buttons vertically for mobile mode */
  @media (max-width: 767px) {
    .action-buttons {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  