.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .carousel-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
  }
  
  .gallery-wrap {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }
  
  .card {
    flex: 0 0 auto;
    margin: 10px;
    text-align: center;
    height: fit-content;
  }
  
  .carousel-image {
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
  }
  
  .left-arrow {
    left: 0;
  }
  
  .right-arrow {
    right: 0;
  }
  
  .button {
    margin-top: 10px;
    width: 100%;
  }
  