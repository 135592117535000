#cv-preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.cv-page {
  width: 210mm; /* Full A4 width */
  height: auto; /* Let the height adjust based on content */
  margin-bottom: 10px; /* Space between pages */
  transform-origin: top left;
}

.cv-preview-container {
  width: 150px; /* Small preview container width */
  height: 200px; /* Small preview container height */
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}



.modal-preview-body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cv-modal-preview {
  width: 100%; /* Full width of the modal */
  height: auto; /* Maintain aspect ratio */
  transform-origin: top left; /* Scale from the top-left corner */
  display: flex;
  flex-direction: column;
  align-items: center;
}
