/* Container and Content Styling */
.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    width: 100%;
    max-width: 1140px;
    background-color: #ffffff;
  
}

.forgot-password-content {
    background-color: #ffffff;
    padding: 40px 30px;
    max-width: 1140px;
    width: 100%;
    border-radius: 12px;
  
    text-align: center;
}

/* Title Styling */
.forgot-password-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 15px;
    color: #2d3748; /* Darker color for better readability */
    padding-top: 40px;
}

/* Form Styling */
.forgot-password-form {
    width: 100%;
}

.forgot-password-form-item {
    margin-bottom: 20px;
    text-align: left;
    position: relative;
}

.forgot-password-form-item label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    color: #4a5568; /* Consistent color with other text elements */
}

.forgot-password-input {
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f7fafc; /* Slight background color for inputs */
    box-sizing: border-box;
}

.forgot-password-input:focus {
    border-color: #3182ce;
    box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.3); /* Slight shadow for focus */
    outline: none;
}

/* Button Styling */
.forgot-password-button {
    width: 100%;
    background-color: #0072bf;
    color: white;
    font-size: 16px;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    text-align: center;
    box-sizing: border-box;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.forgot-password-button:hover {
    background-color: #005999;
    box-shadow: 0 5px 10px rgba(0, 114, 191, 0.3); /* Add slight shadow on hover */
}

/* Back to Login Row and Link Styling */
.back-to-login-row {
    margin-top: 20px;
    text-align: center;
}

.back-to-login-link {
    margin-left: 5px;
    color: #3182ce;
    text-decoration: none;
    font-weight: 500;
}

.back-to-login-link:hover {
    text-decoration: underline;
}
