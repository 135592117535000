.cvgenerate-layout {
    background-color: white;
  }
  
  .cvgenerate-content {
 max-width: 1140px;
 width: 100%;
    margin-top: 64px;
    background-color: #fff;
    
    margin-right: 2px;
  }
  
  .cvgenerate-title-container1 {
    text-align: center;
    background-color: #0072BF;
    display: grid;
    width: 100%;
    max-width: 1140px;
  
  }
  
  .cvgenerate-title {
    color: white;
  }
  
  .cvgenerate-mobile-padding {
    padding: 0px 20px;
  }
  
  .cvgenerate-row {
    padding: 8.5%;
    width: 90%;
  }
  
  .cvgenerate-desktop {
    padding: 0%;
    width: 100%;
  }
  
  .cvgenerate-mobile {
    padding: 0%;
    width: 100%;
  }
  
  .cvgenerate-image-col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
  }
  
  .cvgenerate-image {
    width: 90%;
    height: 100%;
    padding-left: 5%;
  }
  
  .cvgenerate-text-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    padding-left: 20px;
  }
  
  .cvgenerate-icon {
    color: #0072BF;
    font-size: 30px;
  }
  
  .cvgenerate-paragraph {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    text-align: left;
  }
  
  .cvgenerate-paragraph-mobile {
    text-align: center;
  }
  
  .cvgenerate-button-container {
    text-align: left;
    margin-top: 20px;
  }
  
  .cvgenerate-button {
    background-color: #0072BF;
    border-color: #0072BF;
    font-size: 18px;
    padding: 0 20px;
  }
  
  .cvgenerate-job-row {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 95%;
    margin-left: auto;
  }
  
  .cvgenerate-job-desktop {
    width: 88%;
  }
  
  .cvgenerate-job-mobile {
    width: 92%;
  }
  
  .cvgenerate-job-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  