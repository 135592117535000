.right-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;
}

.expert-tips {
  background-color: #f3cc8d; /* Light yellow background */
  color: #0f2247; /* Dark blue text */
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  top: 50px;
  right: 20px;
  background-color: #f3cc8d; /* Light yellow background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.tooltip-content {
  padding: 20px;
  color: #0f2247; /* Dark blue text */
}

.tooltip-content h4 {
  margin: 0 0 10px 0;
  font-size: 16px;
}

.tooltip-content p {
  margin: 0 0 20px 0;
  font-size: 14px;
}

.tooltip-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 10px;
}

.nav-button {
  background: none;
  border: none;
  color: #0f2247; /* Dark blue text */
  font-size: 18px;
  cursor: pointer;
}

.got-it {
  color: #0f2247; /* Dark blue text */
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
