/* Container Styling */
.contact-us-container {

    width: 100%;
    max-width: 1140px;
    margin-top: 70px;
    background-color: #fff; /* Light background color for contrast */
}

/* Title Styling */
.contact-us-title {
    font-size: 32px;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 30px;
    text-align: center; /* Center align the title for better presentation on smaller screens */
}

/* Form Styling */
.contact-us-form {
    max-width: 100%;
    margin: 0 auto; /* Center the form */
}

.contact-us-input,
.contact-us-textarea {
    max-width: 100%;
    width: 100%;
    padding: 12px 15px;
    font-size: 16px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f7fafc;
    box-sizing: border-box;
}

.contact-us-input:focus,
.contact-us-textarea:focus {
    outline: none;
}

/* Button Styling */
.contact-us-button {
    background-color: #0072bf;
    color: white;
    font-size: 18px;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: flex;
   
}

.contact-us-button:hover {
    background-color: #005999;
    box-shadow: 0 5px 10px rgba(0, 114, 191, 0.3);
}

.contact-us-button-icon {
    font-size: 20px;
    margin-right: 10px;
}

/* Image Styling */
.contact-us-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px; /* Add space between form and image */
}

/* Responsive Styling */
@media (max-width: 1024px) {
    /* Tablet */
    .contact-us-container {
        padding: 40px 20px;
    }

    .contact-us-title {
        font-size: 28px;
    }

    .contact-us-button {
        max-width: 180px;
        font-size: 16px;
    }
}

@media (max-width: 768px) {
    /* Mobile */
    .contact-us-container {
        padding: 20px 10px;
        margin-top: 50px;
    }

    .contact-us-title {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .contact-us-button {
        max-width: 160px;
        font-size: 14px;
        padding: 10px 15px;
    }

    .contact-us-input,
    .contact-us-textarea {
        padding: 10px 12px;
        font-size: 14px;
    }

    .contact-us-button-icon {
        font-size: 18px;
        margin-right: 8px;
    }
}
