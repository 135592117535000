.sidebar {
  background-color: #0f2247; /* Dark blue background */
  padding: 20px;
  height: 100vh;
  width: 250px; /* Adjust width as necessary */
  transition: width 0.3s ease;
  position: relative;
}

.sidebar.collapsed {
  width: 60px;
}

.toggle-button {
  background-color: #f3cc8d; /* Light yellow */
  color: #0f2247; /* Dark blue */
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: -20px;
  z-index: 1000;
  transition: right 0.3s ease;
}

.sidebar.collapsed .toggle-button {
  right: -10px;
}

.steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}

.step-icon {
  background-color: #f3cc8d; /* Light yellow background */
  color: #0f2247; /* Dark blue text */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  position: relative;
}



.step-title {
  color: #ffffff; /* White text */
  margin-left: 10px;
  font-size: 16px;
}

.completed .step-icon {
  background-color: #f3cc8d; /* Light yellow for completed steps */
  color: #0f2247; /* Dark blue for checkmark */
}

.current .step-icon {
  background-color: #ffffff; /* White for the current step */
  color: #0f2247; /* Dark blue for the current step number */
  border: 2px solid #f3cc8d; /* Light yellow border */
}

.step-connector {
  position: absolute;
  left: 15px;
  top: 40px;
  width: 2px;
  height: 20px;
}

.solid {
  background-color: #f3cc8d; /* Light yellow color for solid lines */
}

.dotted {
  border-left: 2px dotted #f3cc8d; /* Light yellow color for dotted lines */
  height: 30px;
}

.current-to-next {
  height: 10px;
}

/* Mobile view */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width for mobile */
    height: 50px; /* Auto height for mobile */
    padding: 10px;
  }

  .step-icon[data-tooltip]:hover:after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: #f3cc8d; /* Light yellow */
    color: #0f2247; /* Dark blue */
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    z-index: 1;
  }

  .steps {
    flex-direction: row; /* Horizontal layout for mobile */
    justify-content: space-between;
    width: 85%; /* 85% width of the screen */
    margin: 0 auto;
  }

  .step {
    flex-direction: column; /* Vertical alignment for step items */
    align-items: center;
    margin-bottom: 0;
    position: relative;
  }

  .step-title {
    display: none; /* Hide step titles in mobile view */
  }

  .step-connector {
    position: absolute;
    top: 15px;
    left: 40px;
    height: 2px;
    width: 20px;
  }

  .solid {
    background-color: #f3cc8d; /* Light yellow color for solid lines */
  }

  .dotted {
    border-top: 2px dotted #f3cc8d; /* Light yellow color for dotted lines */
    width: 30px;
  }

  .toggle-button{
    display: none;
  }

  .current-to-next {
    width: 10px;
  }
}
