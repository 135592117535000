/* General Layout Styles */
.homepage-layout {
  background-color: #f4f6f8;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.homepage-content {
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.preview-container {
  width: 100%;
  height: 40%;
  overflow:scroll;

}

.preview-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.cv-modal-preview {
  width: 100%;
  height: auto;
  transform-origin: top left;
}

.modal-preview-body {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.change-template-container {
  margin-top: 16px;
  text-align: center;
}

.full-width-button {
  width: 100%;
  color: #0070B8;
  font-weight: 600;
  padding: 12px 24px;
  border-radius: 8px;
  transition: color 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.full-width-button:hover {
  color: white;
  background-color: #0070B8;
  box-shadow: 0 4px 12px rgba(0, 112, 184, 0.3);
}

/* Professional Modal Styling */
.ant-modal {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.ant-modal-header {
  background-color: #0070B8;
  color: white;
  padding: 16px 24px;
  border-bottom: 1px solid #e6e6e6;
  position: relative;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

.ant-modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  color: white;
  font-size: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.ant-modal-close:hover {
  color: #FF4C4C;
}

.ant-modal-body {
  padding: 24px;
  background-color: #ffffff;
}

.ant-modal-footer {
  background-color: #f9f9f9;
  border-top: 1px solid #e6e6e6;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.ant-modal-footer .ant-btn-primary {
  background-color: #0070B8;
  border-color: #0070B8;
  font-weight: 600;
  padding: 8px 24px;
  border-radius: 4px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ant-modal-footer .ant-btn-primary:hover {
  background-color: #005f99;
  border-color: #005f99;
  transform: translateY(-2px);
}

.ant-modal-footer .ant-btn-default {
  background-color: #ffffff;
  color: #0070B8;
  border-color: #0070B8;
  font-weight: 500;
  padding: 8px 24px;
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ant-modal-footer .ant-btn-default:hover {
  background-color: #0070B8;
  color: #ffffff;
  border-color: #005f99;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 112, 184, 0.3);
}

/* Additional button styles with icons */
.custom-button {
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.custom-button .anticon {
  font-size: 18px;
}
