/* Layout */
.find-users-layout {
    padding: 0;
    margin-top: 0;
    background-color: white;
    width: 100%;
    max-width: 1140px;
  }
  
  /* Row */
  .find-users-row {
    width: 100%;
    margin-bottom: 20px;
  }
  
  /* Buttons */
  .find-users-button {
    font-size: 16px;
  }
  
  /* Autocomplete */
  .find-users-autocomplete {
    width: 100%;
  }
  
  .find-users-clear-icon, .find-users-search-icon {
    color: #0072BF;
  }
  
  /* Card */
  .salary-information-card {
    width: 100%;
    border-radius: 0;
    margin: 20px 0;
    box-shadow: none;
    border: 2px solid #E0E0E0;
    background-color: #FFFFFF;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
  }
  
  /* Card Title */
  .salary-information-title {
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 0;
  }
  
  /* Card Paragraph */
  .salary-information-paragraph {
    margin-left: 25px;
    margin-top: 8px;
    margin-bottom: 20px;
  }
  
  /* Information Box */
  .salary-information-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #e8e8e8;
    border-radius: 5px;
    padding: 0;
    margin-bottom: 20px;
    background-color: #e8e8e8;
  }
  
  /* Information Text */
  .salary-information-text {
    color: #4f4e4d;
    padding-left: 25px;
  }
  
  /* Information Details */
  .salary-information-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Information Value */
  .salary-information-value {
    margin: 0 10px 0 25px;
    font-weight: bold;
    font-size: 30px;
    color: #000000;
  }
  
  /* Dropdown Button */
  .salary-information-dropdown {
    color: blue;
  }
  
  /* Information Topic */
  .salary-information-topic {
    margin: 20px 0;
    font-size: 18px;
    color: #000000;
    text-align: center;
    font-weight: bold;
  }
  
  /* Space */
  .find-users-space {
    width: 100%;
  }
  
  /* Pagination */
  .find-users-pagination {
    margin-top: 20px;
  }
  