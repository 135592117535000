/* Step6.css */
.step6-container {
  padding: 20px;
  background-color: #f9fafb;
  border-radius: 8px;
}

.back-button {
  margin-bottom: 20px;
}

.step6-header {
  margin-bottom: 20px;
}

.step6-content {
  margin-top: 20px;
}

.card-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.languages-panel {
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 8px;
}

.language-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.language-info {
  flex-grow: 1;
}

.language-actions {
  display: flex;
  gap: 8px;
}

.references-panel {
  padding: 20px;
  background-color: #f3f4f6;
  border-radius: 8px;
}

.add-language-form {
  margin-top: 20px;
}
