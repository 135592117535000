.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 0 24px;
  box-shadow: 0 2px 8px #f0f1f2;
}

.logo img {
  height: 40px;
}

.menu-toggle {
  display: none;
}

.admin-menu {
  flex: 1;
}

.admin-menu.horizontal {
  display: flex;
  justify-content: space-around;
}

.admin-menu.vertical {
  display: none;
}

.profile-menu-container {
  display: flex;
  align-items: center;
}

.profile-button {
  margin-left: 16px;
}

.login-button {
  margin-left: 16px;
}

/* Media query for mobile responsiveness */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
  }

  .admin-menu.horizontal {
    display: none;
  }

  .admin-menu.vertical {
    display: block;
    position: absolute;
    top: 64px;
    width: 100%;
  }
}
