/* Container Styles */
.homepage-container {
  
  width: 100%;
  max-width: 1140px;
  margin-top: 90px;
  background-color: #fff; 
}

.homepage-container-mobile {
    padding: 20px 10px;
}

/* Spin Loader Styles */
.homepage-spin {
    display: block;
    margin: 0 auto;
    padding: 50px 0;
}
