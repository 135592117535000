.cv-template3 {
  display: flex;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
  border: 1px solid #ddd;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.cv-left-column,
.cv-right-column {
  width: 50%;
  padding: 40px;
  box-sizing: border-box;
}

.cv-header3 {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.cv-picture3 {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-right: 20px;
  border: 4px solid #eee;
}

.header-text3 h1 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
}

.header-text3 h2 {
  margin-top: 8px;
  font-size: 18px;
  color: #7f8c8d;
}

.contact-section3,
.skills-section3,
.education-section3,
.languages-section3 {
  margin-bottom: 30px;
}

.contact-item3,
.skills-section3 li,
.languages-section3 li {
  font-size: 16px;
  margin-bottom: 10px;
  color: #2c3e50;
  display: flex;
  align-items: center;
}

.contact-item3 svg,
.skills-section3 li::before,
.languages-section3 li::before {
  color: #2980b9;
  margin-right: 10px;
}

.skills-section3 ul,
.languages-section3 ul {
  padding-left: 20px;
  list-style-type: disc;
}

.about-section3,
.experience-section3,
.achievements-section3 {
  margin-bottom: 30px;
}

.about-description3,
.experience-description3 {
  font-size: 16px;
  line-height: 1.6;
  color: #7f8c8d;
}

.experience-item3 h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px;
  color: #2c3e50;
}

.experience-item3 p {
  margin: 4px 0;
  color: #7f8c8d;
}

.education-item3 h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px;
  color: #2c3e50;
}

.cv-left-column h2,
.cv-right-column h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #2c3e50;
  border-bottom: 2px solid #2980b9;
  padding-bottom: 5px;
}
