

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    width: 100%;
    max-width: 1140px;
}

.login-content {
    background-color: #fff;
    padding: 40px 30px;
   max-width: 1140px;
    width: 100%;
    border-radius: 8px;

    text-align: center;
}

.login-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
}

.login-description {
    font-size: 20px;
    color: #555;
    margin-bottom: 30px;
    font-weight: bold;
    line-height: 1.5;
}

.login-form {
    width: 100%;
}

.login-form-item {
    margin-bottom: 20px;
    text-align: left;
    position: relative;
}

.login-form-item label {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.login-form-item input {
    width: 100%;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    background-color: white; /* Ensure the background is white */
}

.login-form-item input:focus {
    border-color: #0072bf;
    outline: none;
}

.password-input {
    position: relative;
}

.password-input input {
    padding-right: 40px; /* Space for the eye icon */
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 70%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px;
    color: #888;
    transition: color 0.3s ease;
}

.toggle-password:hover {
    color: #0072bf;
}

.login-checkbox {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.login-checkbox input {
    margin-right: 10px;
    vertical-align: middle;
}

.login-button2 {
    width: 30%;
    background-color: #0072bf;
    color: white;
    font-size: 16px;
    padding: 12px;

    border-radius: 4px;
    cursor: pointer;
 
    font-weight: 600;
    text-align: center; /* Ensure the text is centered */
    box-sizing: border-box;
}

.login-button:hover {
    background-color: #005999;
}

.social-login-button {
    width: 30%;
    color: white;
    font-size: 16px;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
    cursor: pointer;
 
    font-weight: 600;
    text-align: center; /* Ensure the text is centered */
    box-sizing: border-box;
}
.buttonList{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
    margin-top: 20px;
}

.social-login-button.facebook {
    background-color: #3b5998;
}

.social-login-button.facebook:hover {
    background-color: #2d4373;
}

.social-login-button.google {
    background-color: #db4437;
}

.social-login-button.google:hover {
    background-color: #c33d32;
}

.social-login-button .icon {
    margin-right: 10px;
    font-size: 18px;
}

.forgot-password-link {
    display: block;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #0072bf;
    text-decoration: none;
    font-weight: 500;
}

.forgot-password-link:hover {
    text-decoration: underline;
}

.register-container {
    
    font-size: 14px;
    color: #555;
   
}

.register-link {
    color: #0072bf;
    text-decoration: none;
    margin-left: 5px;
    font-weight: 500;
}

.register-link:hover {
    text-decoration: underline;
}
