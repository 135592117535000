/* Basic Header Styling */
.header {
  background-color: #fff;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px;
}

.logo {
  flex-shrink: 0; /* Prevents the logo from shrinking */
  margin-right: auto; /* Keeps the logo aligned to the left */
}

.logo img {
  height: 50px;
}

.menu-horizontal {
  display: flex;
  align-items: center;
  margin-left: auto; /* Align the menu to the right */
}

.menu-horizontal .ant-menu-item {
  margin-left: 10px;
}

/* Responsive styling */
@media (max-width: 1224px) {
  .header {
    padding: 0 15px; /* Adjust padding for smaller screens */
  }

  .navbar-container {
    padding: 0;
    max-width: 1200px;
    width: 100%;

  }

  .logo img {
    height: 45px; /* Slightly reduce the logo size */
  }
}

@media (max-width: 879px) and (min-width: 601px) {
  

  .header {
    padding: 0 10px;
  }

  .navbar-container {
    padding: 0;
  
    margin-right: 25px;
  }

  .logo img {
    height: 40px;
  }
}


@media  (max-width: 600px){ 
    .navbar-container {
        padding: 0;
        margin-left: 35px;
        margin-right: 10px;
      }
}
