.content {
    padding: 50px 5px;
    background-color: #fff;
    width: 100%;
    font-family: Arial, sans-serif;
}


.content-desktop {
    margin-top: 64px;
    padding-left: 25px;
}

.row {
    margin-bottom: 40px;
    justify-content: space-between;
}
.text-center {
    text-align: center;
}

.col-centered {
    text-align: center;
}

.col-left {
    text-align: left;
}

.paragraph-bold {
    font-size: 20px;
    font-weight: bold;
}

.paragraph-justify {
    font-size: 16px;
    text-align: justify;
}

.title-margin {
   
    color: black;
    font-family: Arial, sans-serif;
}

.button-primary {
    font-size: large;
    background-color: #0072bf;
    padding: 10px 20px;
    font-family: Arial, sans-serif;
}

.image-full {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.section-title {
    color: #0072bf;
    font-size: 20px;
    text-align: center;
    width: 100%;
}

.paragraph-center {
    color: black;
    font-size: 18px;
    text-align: justify;
}

.img-responsive {
    width: 100%;
    max-width: 1800px;
    height: auto;
    margin-top: 20px;
}
.img-responsive2 {
    width: 98%;
    max-width: 1800px;
    height: auto;
    margin-top: 20px;
}
.margin-lft {
    margin-left: 10px;
}
.margin-rgt {
    /* margin-right: 20px; */
}
@media (max-width: 879px) {
    .col-centered-mobile {
        text-align: center;
    }
    .paragraph-center {
        text-align: center;
    }
    .wrappercol-centered{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-left: -20px;
    }
    .title-margin{
        text-align: center;
        width: 100%;
    }
    .image-full{
        padding-left: 35px;
    }
}
@media (max-width:992px) {
    .content-desktop {
        padding-left: 25px;
    }
    .row{
        /* padding-left: 25px; */
    }
    
    .wrappercol-centered{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
       
    }
}
