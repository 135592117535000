.admin-login-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
  }
  
  
  .admin-login-card {
    padding: 20px;
    width: 600px;
    background-color: #0070B7;
    color: white;

  }
  
  .admin-login-title {
    text-align: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .admin-login-form {
    display: flex;
    flex-direction: column;
  }
  
  .admin-login-input {
    margin-bottom: 15px;
  }
  
  .admin-login-button {
    width: 100%;
    color: white;
    font-size: 25px;
    font-weight: bold;
    padding: 10px;
  }
  