.cv-template4 {
    width: 100%;
    margin: 0 auto;
    border: none;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .cv-header4 {
    display: flex;
    align-items: center;
    background-color: #2d2d2d;
    padding: 20px;
    color: #fff;
    border-radius: 8px;
    position: relative;
    height: 160px; /* Adjust height to accommodate the image */
    overflow: visible; /* Ensure the overflow is visible to show the image */
  }
  
  .cv-header4 .cv-picture {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    bottom: -60px; /* Position image top half inside and bottom half outside the header */
    left: 20px; /* Adjust left position as needed */
    border: 4px solid #fff;
  }
  
  .cv-header4 h1 {
    font-size: 2.5em;
    margin: 0;
    font-weight: bold;
    text-transform: capitalize; /* Ensure first letter is capitalized */
  }
  
  .cv-header h2 {
    font-size: 1.5em;
    margin: 0;
  }
  
  .cv-content {
    display: flex;
    flex-direction: column;
  
  }
  
  .cv-sidebar {
    flex: 1;
    padding: 10px;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: 20px;
  }
  
  .cv-main-content {
    flex: 2;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .section h2 {
    font-size: 1.5em;
    color: #b03a3a;
    border-bottom: 2px solid #b03a3a;
    padding-bottom: 5px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    margin: 5px 0;
    white-space: normal;
  }
  
  .contact-item .anticon {
    margin-right: 10px;
    flex-shrink: 0;
  }
  
  .contact-item p, .contact-item span {
    margin: 0;
    line-height: 1.5;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  
  .skills-section ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .skills-section li {
    font-size: 1em;
  }
  
  .education-item h3, .experience-item h3 {
    font-size: 1.2em;
    margin: 0;
  }
  
  .education-item p, .experience-item p {
    margin: 0;
    text-align: justify;
  }
  
  .education-description, .experience-description, .about-description {
    margin-top: 10px;
    text-align: justify;
  }
  
  .achievements-section p {
    margin-top: 10px;
  }
  
  /* Responsive Styles */
  @media screen and (min-width: 768px) {
    .cv-content {
      flex-direction: row;
    }
  
    .cv-sidebar {
      flex: 0 0 40%;
      max-width: 40%;
    }
  
    .cv-main-content {
      flex: 1;
      max-width: 60%;
    }
  }
  
  @media screen and (max-width: 767px) {
    .cv-header {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .cv-header .cv-picture {
      position: static;
      margin-bottom: 20px;
    }
  
    .cv-header h1 {
      font-size: 2em;
    }
  
    .cv-header h2 {
      font-size: 1.2em;
    }
  
    .cv-sidebar, .cv-main-content {
      max-width: 100%;
    }
  }
  