/* Step4.css */

.back-button {
  margin-bottom: 16px;
}

.page-title-wrap {
  text-align: left;
}

.page-title {
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.sub-title {
  font-size: 1em;
  color: #888;
}

.search-container {
  display: flex;
  margin-bottom: 16px;
}

.search-input {
  flex: 1;
  margin-right: 8px;
}

.search-results-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  background-color: #fff;
}

.search-result-item {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.plus-icon {
  margin-right: 8px;
}

.skills-summary-title {
  margin-top: 32px;
  margin-bottom: 16px;
}

.skills-summary-item {
  margin-bottom: 16px;
}

.skills-summary-card {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-header-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-header-info span {
  font-weight: bold;
}

@media (max-width: 768px) {
  .skills-summary-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-results-container {
    max-height: 300px;
  }
}
