/* General content padding */
.content {
  padding: 20px;
}
.css-dev-only-do-not-override-m4timi .ant-card-bordered {
  box-shadow: none ;
}
.card-paragraph{
  font-size: 16px;
  text-align: justify;
}
.content4{
 
  padding-left: 20px;
}
/* Banner section above the navbar */
.banner {
  text-align: center;
  background-color: #0072BF;
  color: white;
  margin-left: 25px;
  /* width: 92%; */
  width: calc(100% - 50px);
  max-width: 1140px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.banner h2{
  color: #fff;
} 
/* Card container */
.card-container {
  width: 100%;
  margin: 10px 39px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Carousel container */


.image-container {
  display: inline-block;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  text-align: center;
  margin-right: 10px;
}

.image-container img {
  height: 300px;
  object-fit: contain;
  margin: 0 auto;
  border: none;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: white;
  border-radius: 50%;
  padding: 10px;
  z-index: 1;
}
.content2{
  box-shadow:none;
  max-width: 1140px;
  margin-left: 25px;
}
.carousel-container{
  max-width: 1240px;
  width: 100%;
  display: flex;
  overflow-x: auto;
  gap: 10px;
}
.title-margin2{
  text-align: center;
}

.arrow.left {
  left: 0;
}

.arrow.right {
  right: 0;
}

.button-large {
  font-size: large;
  background-color: #0072BF;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: white;
}

.banner-card {
  width: 100%;
  height: 300px;
  background-color: gray;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  padding: 20px;
}
.content5{

  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 1140px;
  width: 100%;
  margin-left: 25px;
  margin-right: 25px;
  
}
/* Responsive adjustments */
@media (max-width: 878px) {
  .content2{
    margin-left: 25px;
    margin-right: 25px;
  }
  .card-container {
    margin: 10px;
  }

  .image-container {
    width: 100%;
    
  }

  .image-container img {
    width: 100%;
    height: auto;
  }

  .banner-card {
    height: 200px;
  }

  .arrow {
    padding: 5px;
  }
}
@media (max-width: 1224px) and(min-width:1013px) {
  .banner {
    margin-left: 37px
  }
}

@media (max-width: 1012px) and(min-width: 601px) {
  .banner {
    margin-left: 0px
  }
}

@media (max-width: 600px) {
  .banner {
    margin-left: 30px;
    margin-right: 9px;
    flex-direction: column;
   
  }
}


