.footer {

    
  }
  
  .footer-container {
    max-width: 1200px;
    margin: 0px auto;
    width: 100%;
   
    display: flex;
    flex-direction: row;

    justify-content: space-between;

  }
  
  .footer-section {
    flex: 1;
    margin-bottom: 32px;
    padding: 0px 50px;
  }
  .footer-section-new {
    flex: 1;
    margin-bottom: 32px;
   display: grid;
  }
  
  .footer-logo {
    width: 240px;
    height: 100px;
    filter: invert(1);
    margin-bottom: 16px;
    margin-left: 0px;
  }
  .footer-special-links{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: auto;
  }
  .footer-contact-us{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: auto;
  }
  .footer-text {
    text-align: justify;
    display: block;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    text-align: justify;
    margin-top: 30px;
    font-size: 16px;
  }
  
  .footer-link-item {
    margin-bottom: 8px;
  }
  
  .footer-link {
    color: #1890ff;
  }
  
  .footer-contact {
    list-style: none;
    padding: 0;
    text-align: justify;
    margin-top: 30px;
    font-size: 16px;
  }
  
  .footer-contact-item {
    display: flex;
  
    margin-bottom: 8px;
    text-align: justify; /* Ensure justified alignment */
  }
  
  .footer-contact-item span {
    margin-left: 8px;
  }
  
  .footer-divider {
    margin: 16px 0;
    border-top: 1px solid #ddd;
  }
  
  .footer-social {
    display: flex;
    justify-content: center;
    gap: 16px;
  }
  
  .footer-icon {
    font-size: 24px;
    color: #1890ff;
  }


  @media screen and (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      padding: 30px;
    }
  
    .footer-section {
      padding: 0px;
    }
  }
  